import { useState, useEffect } from 'react';
import './new-style.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Navbar from './components/ui/Navbar';
import Banner from './components/ui/Banner';
import Salt from './components/ui/Salt';
import LeaderBoard from './components/ui/LeaderBoard';
import FAQ from './components/ui/FAQ';
import Footer from './components/ui/Footer';
import Instruction from './components/ui/Instruction';
import EarnXp from './components/ui/EarnXp';

function App() {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    if (window.pageYOffset <= 344) {
      setOffsetY(window.pageYOffset);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <div className="snap_container y mandatory-scroll-snapping">
        <div className="snap_items">
          <div className="banner_container">
            <div
              className="circle-1"
              style={{
                transform: `translateY(${offsetY * 0.1}px)`,
              }}
            ></div>
            <div
              className="circle-2"
              style={{
                transform: `translateY(${offsetY * 0.1}px)`,
              }}
            ></div>
            <div
              className="circle-3"
              style={{
                transform: `translateY(${offsetY * 0.1}px)`,
              }}
            ></div>
            <Navbar />
            <Banner offsetY={offsetY} />
          </div>
        </div>
        <div className="snap_items">
          <Instruction />
        </div>
        <div className="snap_items">
          <Salt />
        </div>
        <div className="snap_items">
          <EarnXp />
        </div>
        <div className="snap_items">
          <LeaderBoard />
        </div>
        <div className="snap_items">
          <FAQ />
        </div>
        <div className="snap_items">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
