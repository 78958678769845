import React from 'react';

function Stepper({ stage }) {
  return (
    <>
      <div className="register-stepper">
        <div className={`step ${stage === 'stage-1' ? 'done' : 'editing'}`}>
          <div className="step-circle">
            <span>1</span>
          </div>
          <div className="step-bar-left"></div>
          <div className="step-bar-right"></div>
        </div>
        <div className={`step ${stage === 'stage-2' ? 'done' : 'editing'}`}>
          <div className="step-circle">
            <span>2</span>
          </div>
          {/* <div className="step-title h6">Profile</div> */}
          <div className="step-bar-left"></div>
          <div className="step-bar-right"></div>
        </div>
      </div>
    </>
  );
}

export default Stepper;
