import React from 'react';
import Button from './Button';

function Navbar() {
  return (
    <nav className="navbar" id="navbar">
      <div>
        <a href="https://www.brine.finance/">
          <img src="https://www.brine.finance/img/brine-logo-nas.png" alt="" />
        </a>
      </div>
      <div>
        <Button
          onClick={() =>
            window.open('https://testnet.brine.finance/', {
              target: '_blank',
            })
          }
        >
          Open App
        </Button>
      </div>
    </nav>
  );
}

export default Navbar;
