import React from 'react';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer__center container'>
        <a className='footer__logo sm_company_logo' href='#'>
          <img
            className='footer__pic'
            src='https://www.brine.finance/img/brine-logo-nas.png'
            alt=''
          />
        </a>
        <div className='footer__row'>
          <div className='footer__col company_logo'>
            <a className='footer__logo' href='#'>
              <img
                className='footer__pic'
                src='https://www.brine.finance/img/brine-logo-nas.png'
                alt=''
              />
            </a>
          </div>
          <div className='footer__col js-footer-col'>
            <div className='footer__category js-footer-category'>
              Company
              <svg className='icon icon-arrow-down'>
                <use xlinkHref='img/sprite.svg#icon-arrow-down'></use>
              </svg>
            </div>
            <div className='footer__menu js-footer-menu'>
              <a
                className='footer__link'
                target='__blank'
                href='https://careers.brine.finance'
                rel='noopener noreferrer'
              >
                Careers
              </a>
              <a
                className='footer__link'
                href='https://www.brine.finance/brand.html'
              >
                Brand
              </a>
            </div>
          </div>
          <div className='footer__col js-footer-col'>
            <div className='footer__category js-footer-category'>
              Community
              <svg className='icon icon-arrow-down'>
                <use xlinkHref='img/sprite.svg#icon-arrow-down'></use>
              </svg>
            </div>
            <div className='footer__menu js-footer-menu'>
              <a
                className='footer__link'
                href='https://twitter.com/BrineFinance'
                target='_blank'
                rel='noopener noreferrer'
              >
                Twitter
              </a>
              <a
                className='footer__link'
                href='https://discord.gg/wuPHBr9e9y'
                target='_blank'
                rel='noopener noreferrer'
              >
                Discord
              </a>
            </div>
          </div>
        </div>
        <div className='footer__bottom'>
          <a className='footer__company' href='' target='_blank'>
            <img className='footer__pic' src='' alt='' />
          </a>
          <div className='footer__copyright'>
            © 2022, Brine. | Inventphile Ventures Inc. (Delaware, Bangalore, New
            Delhi, California) 🌎
          </div>
          <a className='footer__scroll js-link-scroll' href='#navbar'>
            <div className='footer__icon'>
              <img
                className='footer__pic'
                src='https://www.brine.finance/img/scroll.svg'
                alt=''
              />
            </div>
            <div className='footer__text'>Back to top</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
