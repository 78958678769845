import React, { useState } from 'react';
import BGImage from '../../assets/Path 2543.png';
import TrophyImg from '../../assets/trophy-dynamic-gradient.png';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <div>{title}</div>
          <div className="active_symbol">
            {isActive ? (
              <i className="fas fa-angle-up"></i>
            ) : (
              <i className="fas fa-angle-down"></i>
            )}
          </div>
        </div>
        {isActive && <div className="accordion-content">{content}</div>}
      </div>
    </>
  );
};

const FAQData = [
  {
    title: 'What does SALT Score mean?',
    content: (
      <p>
        Your SALT Score on Brine, is the score assigned to your public address
        as per your interactions with the blockchain such as lending, borrowing,
        staking and your engagement with Brine on social media such as Discord
        and Twitter.
      </p>
    ),
  },
  {
    title: 'How would a high SALT Score benefit me ?',
    content: (
      <div>
        <p>Having a high SALT Score will make you eligible for claiming</p>
        <ol>
          <li>Brinerd NFT Collection</li>
          <li>Exclusive Merchandize</li>
          <li>Reduced Trading Fee on Brine</li>
          <li>VIP Access to Brine Events</li>
        </ol>
      </div>
    ),
  },
  {
    title: 'How do I improve my SALT Score?',
    content: (
      <p>
        You can improve your SALT Score by interacting and engaging with the
        Brinerd community after connecting your Twitter and Discord account on
        this page.
      </p>
    ),
  },
  {
    title: 'Is my Social Media Data safe?',
    content: (
      <p>
        Just how we provide complete privacy for your trading positions on Brine
        by making use of ZK Proofs, using the same ideology we protect any of
        your other affiliated data.
      </p>
    ),
  },
  {
    title: 'When can I claim my SALT Score benefits?',
    content: <p>You can claim them on the Brine rewards page in sometime.</p>,
  },
];

function FAQ() {
  return (
    <div style={{ position: 'relative' }}>
      <div className="trophy__mobile" style={{ position: 'relative' }}>
        <img src={TrophyImg} alt="" />
      </div>
      <div className="instruction" style={{ marginTop: 120 }}>
        <h3>
          Frequently Asked Questions
          <span className="color-purple">.</span>
        </h3>
        <p className="instruction_subtitle">
          If you have a question not listed in here - do reach us through our
          support email, we are at your service.
        </p>
      </div>
      <div className="accordian_container">
        <div className="accordion">
          {FAQData.map(({ title, content }) => (
            <Accordion title={title} content={content} key={title} />
          ))}
        </div>
      </div>
      {/* <div className="bg-faq-bg-line">
        <img src={BGImage} alt="" />
      </div> */}
    </div>
  );
}

export default FAQ;
