export const crew3XpData = [
  {
    name: 'Getting-started✅',
    createdAt: '2022-06-02T06:42:35.312Z',
    updatedAt: '2022-06-06T09:42:58.539Z',
    deleted: false,
    position: 0,
    communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
    id: '1f12ab6b-c321-435d-821a-b9789c4c21b4',
    quests: [
      {
        name: 'Discord Buddy 👋',
        description: 'Join our Discord server to get the Brinerd role',
        content: [
          {
            id: '_liWEPz9En',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'ZVyz-qBlv5',
            data: {
              text: 'Join our Discord server to get the Brinerd role:',
            },
            type: 'paragraph',
          },
          {
            id: '2C_18Fhr6R',
            data: {
              text: 'https://discord.gg/wuPHBr9e9y',
            },
            type: 'paragraph',
          },
          {
            id: 'F-UFgTURoz',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'BVZqw3Et1u',
            data: {
              text: 'Screenshot of Brinerds role.',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-02T06:42:35.314Z',
        updatedAt: '2022-06-06T11:50:18.866Z',
        reward: [
          {
            type: 'xp',
            value: '30',
          },
        ],
        position: 0,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'image',
        condition: {},
        validationData: {},
        autoValidate: false,
        id: 'f5a65a0f-aea3-4f2b-8d56-06d29079cac6',
        categoryId: '1f12ab6b-c321-435d-821a-b9789c4c21b4',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Twitter Buddy 🙌🏼',
        description: 'Twitter: follow',
        content: [
          {
            id: 'JSgJm_gMlc',
            data: {
              text: 'MISSION 🎯',
              level: 1,
            },
            type: 'header',
          },
          {
            id: '1UjU4Wwdvj',
            data: {
              text: 'Follow us on Twitter&nbsp;',
            },
            type: 'paragraph',
          },
          {
            id: '3_SKgtX7U2',
            data: {
              text: 'GUIDE 📚',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'Fm5BukGobI',
            data: {
              text: "Check the next community call date on: https://example.com, pop in and don't hesitate to ask questions!",
            },
            type: 'paragraph',
          },
          {
            id: 'u9nPQu1DLS',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'WLyMrFLzBy',
            data: {
              text: 'Submit a screenshot as a proof.',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-02T06:42:35.314Z',
        updatedAt: '2022-06-06T11:00:36.072Z',
        reward: [
          {
            type: 'xp',
            value: '30',
          },
        ],
        position: 1,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'twitter',
        condition: {},
        validationData: {
          actions: ['follow'],
          tweetUrl: '',
          defaultReply: '',
          defaultTweet: '',
          tweetHashtags: '',
          twitterHandle: 'BrineFinance',
          mentionAccount: '',
        },
        autoValidate: true,
        id: 'b7ae51a1-ee0b-4471-84a8-1274ca3dd96b',
        categoryId: '1f12ab6b-c321-435d-821a-b9789c4c21b4',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Introduce Yourself 🙏🏼',
        description: 'Post an intro in #introduce-yourself channel on Discord',
        content: [
          {
            id: 'JwVLtSXUYZ',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'KiBUUrVK7n',
            data: {
              text: 'Post an intro in #introduce-yourself channel on Discord',
            },
            type: 'paragraph',
          },
          {
            id: 'WqJo09t-y7',
            data: {
              text: 'Feel free to tell the community anything about you! Ideas on things you can talk about:',
            },
            type: 'paragraph',
          },
          {
            id: '0thTXlK9m3',
            data: {
              text: '- What do you do in real life?',
            },
            type: 'paragraph',
          },
          {
            id: 'zUtIswMG9F',
            data: {
              text: '- What got you into crypto?',
            },
            type: 'paragraph',
          },
          {
            id: 'Rwja8JJB3I',
            data: {
              text: '- How long have you been in crypto?',
            },
            type: 'paragraph',
          },
          {
            id: '1ukuQuu2jw',
            data: {
              text: '- How did you first hear about Brine?',
            },
            type: 'paragraph',
          },
          {
            id: 'UJEzP3jT_h',
            data: {
              text: '- What about Brine that excites you?',
            },
            type: 'paragraph',
          },
          {
            id: '0U_RtKfjrG',
            data: {
              text: '- What are your hobbies / favorite food?',
            },
            type: 'paragraph',
          },
          {
            id: 'VpgBqnnaeG',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'tVSahNNa4q',
            data: {
              text: 'Screenshot of your message in #introduce-yourself channel.',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-02T06:42:35.314Z',
        updatedAt: '2022-06-06T09:41:08.246Z',
        reward: [
          {
            type: 'xp',
            value: 50,
          },
        ],
        position: 2,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'image',
        condition: {
          type: 'quest',
          value: 'f5a65a0f-aea3-4f2b-8d56-06d29079cac6',
          operator: 'isCompleted',
        },
        validationData: {},
        autoValidate: false,
        id: '4f4ec254-bef0-41ae-a162-01bb924763ad',
        categoryId: '1f12ab6b-c321-435d-821a-b9789c4c21b4',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Youtube Buddy 📹',
        description: 'Subscribe to our youtube channel',
        content: [
          {
            id: '6voL3PL_YN',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'skzS_CxGxH',
            data: {
              text: 'Subscribe to our youtube channel :',
            },
            type: 'paragraph',
          },
          {
            id: 'CXbwyMspWg',
            data: {
              text: 'https://www.youtube.com/channel/UCUG2L75yvKJBK9QFUaXTdyA',
            },
            type: 'paragraph',
          },
          {
            id: 'Vko5K94LWN',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '5Skqjg07bE',
            data: {
              text: 'Screenshot of subscribing the channel.',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-06T11:08:40.068Z',
        updatedAt: '2022-06-06T11:55:43.772Z',
        reward: [
          {
            type: 'xp',
            value: '30',
          },
        ],
        position: 3,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'image',
        condition: {
          type: 'quest',
          value: 'b7ae51a1-ee0b-4471-84a8-1274ca3dd96b',
          operator: 'isCompleted',
        },
        validationData: {},
        autoValidate: false,
        id: '445ddef3-2c16-44fb-a4c4-ac00df5b5416',
        categoryId: '1f12ab6b-c321-435d-821a-b9789c4c21b4',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Level Up Quest 📈',
        description: 'Twitter: follow, retweet, like',
        content: [
          {
            id: '1',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '2',
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            id: '3',
            data: {
              text: 'GUIDE 📚',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '4',
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            id: '5',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '6',
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-07T20:09:19.842Z',
        updatedAt: '2022-06-07T20:09:19.842Z',
        reward: [
          {
            type: 'xp',
            value: '50',
          },
          {
            type: 'role',
            value: '983823732256702584',
          },
        ],
        position: 4,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'twitter',
        condition: {},
        validationData: {
          actions: ['follow', 'retweet', 'like'],
          tweetId: '1514232275507982340',
          tweetUrl:
            'https://twitter.com/BrineFinance/status/1514232275507982340',
          defaultReply: '',
          defaultTweet: '',
          tweetHashtags: '',
          twitterHandle: 'BrineFinance',
          mentionAccount: '',
        },
        autoValidate: true,
        id: '4d7bee97-f1ec-4149-bbd4-9f4ee6e2507f',
        categoryId: '1f12ab6b-c321-435d-821a-b9789c4c21b4',
        status: 'open',
        inReview: false,
        locked: false,
      },
    ],
  },
  {
    name: 'Brinerd-Pro🔥',
    createdAt: '2022-06-02T06:42:35.312Z',
    updatedAt: '2022-06-06T09:42:53.633Z',
    deleted: false,
    position: 1,
    communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
    id: '761a7e49-ad49-447c-98e7-7fb6cd1bb7b6',
    quests: [
      {
        name: 'Hype the TraderNerd 🚀',
        description: 'Twitter: like, retweet',
        content: [
          {
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            data: {
              text: 'GUIDE 📚',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-08T12:09:51.113Z',
        updatedAt: '2022-06-08T12:09:51.113Z',
        reward: [
          {
            type: 'xp',
            value: '50',
          },
        ],
        position: 2,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'twitter',
        condition: {
          type: 'quest',
          value: 'b7ae51a1-ee0b-4471-84a8-1274ca3dd96b',
          operator: 'isCompleted',
        },
        validationData: {
          actions: ['like', 'retweet'],
          tweetId: '1534507441508868097',
          tweetUrl:
            'https://twitter.com/BrineFinance/status/1534507441508868097',
          defaultReply: '',
          defaultTweet: '',
          tweetHashtags: '',
          twitterHandle: 'BrineFinance',
          mentionAccount: '',
        },
        autoValidate: true,
        id: '381f21b9-a46b-4ae5-a719-2e8b5494db0b',
        categoryId: '761a7e49-ad49-447c-98e7-7fb6cd1bb7b6',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Shill Crew3 💪',
        description: 'Twitter: like, retweet',
        content: [
          {
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            data: {
              text: 'GUIDE 📚',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-07T12:38:49.205Z',
        updatedAt: '2022-06-07T12:38:49.205Z',
        reward: [
          {
            type: 'xp',
            value: '50',
          },
        ],
        position: 3,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'twitter',
        condition: {
          type: 'quest',
          value: 'b7ae51a1-ee0b-4471-84a8-1274ca3dd96b',
          operator: 'isCompleted',
        },
        validationData: {
          actions: ['like', 'retweet'],
          tweetId: '1534151805281648643',
          tweetUrl:
            'https://twitter.com/BrineFinance/status/1534151805281648643',
          defaultReply: '',
          defaultTweet: '',
          tweetHashtags: '',
          twitterHandle: '',
          mentionAccount: '',
        },
        autoValidate: true,
        id: 'c00fe6a5-2fcf-404d-a565-ccecd626daa9',
        categoryId: '761a7e49-ad49-447c-98e7-7fb6cd1bb7b6',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Engage with the Brinerd Community',
        description: 'Twitter: like, retweet, follow',
        content: [
          {
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            data: {
              text: 'GUIDE 📚',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-06T11:19:56.169Z',
        updatedAt: '2022-06-06T11:19:56.169Z',
        reward: [
          {
            type: 'xp',
            value: '50',
          },
        ],
        position: 4,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'twitter',
        condition: {},
        validationData: {
          actions: ['like', 'retweet', 'follow'],
          tweetId: '1519666166507077632',
          tweetUrl:
            'https://twitter.com/BrineFinance/status/1519666166507077632',
          defaultReply: '',
          defaultTweet: '',
          tweetHashtags: '',
          twitterHandle: 'BrineFinance',
          mentionAccount: '',
        },
        autoValidate: true,
        id: 'fbe9e6c6-e5d9-453b-9527-34588de276cc',
        categoryId: '761a7e49-ad49-447c-98e7-7fb6cd1bb7b6',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Answer on Discord 💪',
        description: 'Help a fellow Brinerd on Discord 😉',
        content: [
          {
            id: 'RTqUbGTYHa',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'XyRy0LIygk',
            data: {
              text: 'Help a fellow Brinerd on Discord 😉',
            },
            type: 'paragraph',
          },
          {
            id: '1idivlgb2g',
            data: {
              text: 'GUIDE 📚',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'evhvl4uRMo',
            data: {
              text: "Answer any query regarding the project on Discord and you'll be rewarded for your efforts 🥳",
            },
            type: 'paragraph',
          },
          {
            id: 'TVu3wAOHyz',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'ACqRtqipHe',
            data: {
              text: "Submit a screenshot of your conversation solving a Brinerd's doubt on any public channel on Brine Discord 🚀",
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'weekly',
        deleted: false,
        createdAt: '2022-06-06T11:28:50.432Z',
        updatedAt: '2022-06-06T11:28:50.432Z',
        reward: [
          {
            type: 'xp',
            value: '50',
          },
        ],
        position: 5,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'image',
        condition: {
          type: 'quest',
          value: 'f5a65a0f-aea3-4f2b-8d56-06d29079cac6',
          operator: 'isCompleted',
        },
        validationData: {},
        autoValidate: false,
        id: 'dbfc97e8-2d4a-461e-ac19-e5c7579cfee8',
        categoryId: '761a7e49-ad49-447c-98e7-7fb6cd1bb7b6',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Tweet about us 🚀',
        description: 'Twitter: tweet, follow',
        content: [
          {
            id: 'u-4aXCj79B',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'TTLN3XT87G',
            data: {
              text: 'Tweet&nbsp;',
            },
            type: 'paragraph',
          },
          {
            id: '0mI8s8u9dx',
            data: {
              text: 'GUIDE 📚',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'ncVLcEx7E9',
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            id: 's1OitgBL7-',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'fYx6IvsS0u',
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-06T11:34:29.024Z',
        updatedAt: '2022-06-06T11:34:29.024Z',
        reward: [
          {
            type: 'xp',
            value: '100',
          },
        ],
        position: 6,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'twitter',
        condition: {},
        validationData: {
          actions: ['tweet', 'follow'],
          tweetUrl: '',
          defaultReply: '',
          defaultTweet: '',
          tweetHashtags: '#brine #dex #ethereum',
          twitterHandle: 'BrineFinance',
          mentionAccount: 'BrineFinance',
        },
        autoValidate: true,
        id: '43732b3c-28ee-4574-9707-a4797b66c36c',
        categoryId: '761a7e49-ad49-447c-98e7-7fb6cd1bb7b6',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'The Cairo Way of building 🚀',
        description: 'Twitter: like, retweet',
        content: [
          {
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            data: {
              text: 'GUIDE 📚',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-06T11:55:05.210Z',
        updatedAt: '2022-06-06T11:55:05.210Z',
        reward: [
          {
            type: 'xp',
            value: '50',
          },
        ],
        position: 7,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'twitter',
        condition: {},
        validationData: {
          actions: ['like', 'retweet'],
          tweetId: '1527226549723754497',
          tweetUrl:
            'https://twitter.com/BrineFinance/status/1527226549723754497',
          defaultReply: '',
          defaultTweet: '',
          tweetHashtags: '',
          twitterHandle: '',
          mentionAccount: '',
        },
        autoValidate: true,
        id: '46358247-3fb4-4a95-bcd5-376382cfe374',
        categoryId: '761a7e49-ad49-447c-98e7-7fb6cd1bb7b6',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'The Legendary Memer 👑',
        description:
          "Post a meme on Brine's Discord Server in the #memes channel to claim this quest 💪",
        content: [
          {
            id: 'Nac27WFY_B',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'hQqAzhFFZc',
            data: {
              text: "Post a meme on Brine's Discord Server in the #memes channel to claim this quest 💪",
            },
            type: 'paragraph',
          },
          {
            id: 'H6Z6G04hb_',
            data: {
              text: 'GUIDE 📚',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '7XD0NyX2WW',
            data: {
              text: '1. Join our Discord Server by completing Discord Buddy 👋',
            },
            type: 'paragraph',
          },
          {
            id: 'Xx9fkSqGJM',
            data: {
              text: '2. Unleash your meme skills 😉',
            },
            type: 'paragraph',
          },
          {
            id: 'g0awSOM9Nc',
            data: {
              text: '3. Put the meme up on the #memes channel 🚀',
            },
            type: 'paragraph',
          },
          {
            id: 'bPUjsTR9tZ',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'tYnZcfDxnb',
            data: {
              text: 'Submit a screenshot of the #memes channel where you posted the Meme.',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'weekly',
        deleted: false,
        createdAt: '2022-06-06T12:09:08.060Z',
        updatedAt: '2022-06-06T12:09:08.060Z',
        reward: [
          {
            type: 'xp',
            value: '50',
          },
        ],
        position: 8,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'image',
        condition: {
          type: 'quest',
          value: 'f5a65a0f-aea3-4f2b-8d56-06d29079cac6',
          operator: 'isCompleted',
        },
        validationData: {},
        autoValidate: false,
        id: 'e3f5bafb-7565-4fce-8d6a-f017ad07dc52',
        categoryId: '761a7e49-ad49-447c-98e7-7fb6cd1bb7b6',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Building as a STARK🚀 ',
        description: 'Twitter: like, retweet',
        content: [
          {
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            data: {
              text: 'GUIDE 📚',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
          {
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'text',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-06T16:05:11.462Z',
        updatedAt: '2022-06-06T16:06:00.981Z',
        reward: [
          {
            type: 'xp',
            value: '50',
          },
        ],
        position: 9,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'twitter',
        condition: {
          type: 'quest',
          value: 'b7ae51a1-ee0b-4471-84a8-1274ca3dd96b',
          operator: 'isCompleted',
        },
        validationData: {
          actions: ['like', 'retweet'],
          tweetId: '1533842279089475584',
          tweetUrl:
            'https://twitter.com/BrineFinance/status/1533842279089475584',
          defaultReply: '',
          defaultTweet: '',
          tweetHashtags: '',
          twitterHandle: '',
          mentionAccount: '',
        },
        autoValidate: true,
        id: '55c86b44-1636-4eb4-aa39-9ec4ae5eb25e',
        categoryId: '761a7e49-ad49-447c-98e7-7fb6cd1bb7b6',
        status: 'open',
        inReview: false,
        locked: false,
      },
    ],
  },
  {
    name: 'community',
    createdAt: '2022-06-02T06:42:35.312Z',
    updatedAt: '2022-06-02T06:42:35.312Z',
    deleted: false,
    position: 2,
    communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
    id: '4ccdaf6c-433f-4272-a201-19df9ccb3d6d',
    quests: [
      {
        name: 'Your quest 📜',
        description: 'Suggest a meaningful quest for the community',
        content: [
          {
            data: {
              text: 'Your quest 📜',
              level: 1,
            },
            type: 'header',
          },
          {
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'Suggest a meaningful quest for the community.',
            },
            type: 'paragraph',
          },
          {
            data: {
              text: 'GUIDE 📚',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'What kind of quests can we offer to :',
            },
            type: 'paragraph',
          },
          {
            data: {
              items: ['Create value for the project'],
              style: 'unordered',
            },
            type: 'list',
          },
          {
            data: {
              items: ['Bring visibility'],
              style: 'unordered',
            },
            type: 'list',
          },
          {
            data: {
              items: ['Engage the community'],
              style: 'unordered',
            },
            type: 'list',
          },
          {
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            data: {
              text: 'Upload your quest idea on a Google Doc (and make it public!)',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'weekly',
        deleted: false,
        createdAt: '2022-06-02T06:42:35.314Z',
        updatedAt: '2022-06-06T12:09:55.723Z',
        reward: [
          {
            type: 'xp',
            value: '10',
          },
        ],
        position: 0,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'url',
        condition: {},
        validationData: {},
        autoValidate: false,
        id: 'cb8c57ba-1b62-43eb-bbe4-290248544257',
        categoryId: '4ccdaf6c-433f-4272-a201-19df9ccb3d6d',
        status: 'open',
        inReview: false,
        locked: false,
      },
    ],
  },
  {
    name: 'OG👑',
    createdAt: '2022-06-07T12:39:22.965Z',
    updatedAt: '2022-06-07T12:39:22.965Z',
    deleted: false,
    position: 3,
    communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
    id: '1c4f1e4b-b099-4b70-a2ae-aeefc1949ea5',
    quests: [
      {
        name: 'Participate in a Brine AMA',
        description: 'Join a Brine AMA',
        content: [
          {
            id: '1',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '2',
            data: {
              text: 'Join a Brine AMA&nbsp;',
            },
            type: 'paragraph',
          },
          {
            id: '5',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '6',
            data: {
              text: 'Submit a screenshot of attending the AMA on Twitter / Discord (LIVE | Must attend it live - we will hosting one next week⚡️ )&nbsp;',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-07T19:19:51.490Z',
        updatedAt: '2022-06-07T19:19:51.490Z',
        reward: [
          {
            type: 'xp',
            value: '100',
          },
        ],
        position: 0,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'image',
        condition: {
          type: 'quest',
          value: 'b7ae51a1-ee0b-4471-84a8-1274ca3dd96b',
          operator: 'isCompleted',
        },
        validationData: {},
        autoValidate: false,
        id: '256bfa3e-2c88-4dfb-86f3-a657569d7966',
        categoryId: '1c4f1e4b-b099-4b70-a2ae-aeefc1949ea5',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Fanny Manny Magnet 👫',
        description:
          'Tag 2 or more of your friends on the following twitter post',
        content: [
          {
            id: '1',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '2',
            data: {
              text: 'Tag 2 or more of your friends on the following twitter post :',
            },
            type: 'paragraph',
          },
          {
            id: 'KtYylfBKhu',
            data: {
              text: 'https://twitter.com/BrineFinance/status/1534151805281648643',
            },
            type: 'paragraph',
          },
          {
            id: '5',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '6',
            data: {
              text: 'Submit a screenshot of tagging your friends on the twitter post.',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-07T19:25:55.348Z',
        updatedAt: '2022-06-07T19:25:55.348Z',
        reward: [
          {
            type: 'xp',
            value: '100',
          },
        ],
        position: 1,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'image',
        condition: {
          type: 'quest',
          value: 'b7ae51a1-ee0b-4471-84a8-1274ca3dd96b',
          operator: 'isCompleted',
        },
        validationData: {},
        autoValidate: false,
        id: '56366ff7-5a7e-4cae-88e6-c1b704b4f270',
        categoryId: '1c4f1e4b-b099-4b70-a2ae-aeefc1949ea5',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'OGGGG 🥁🥁🥁🥁',
        description: '- Complete all the Quests',
        content: [
          {
            id: 'z2pBBkDWOp',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '6fhsByZJFW',
            data: {
              text: '- Complete all the Quests',
            },
            type: 'paragraph',
          },
          {
            id: 'Vo0cHl9guW',
            data: {
              text: '- Apply for the role on or after 11th June&nbsp;',
            },
            type: 'paragraph',
          },
          {
            id: 'oYNsCEDiDZ',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: 'RtCEC6zzXD',
            data: {
              text: 'Submit Screenshot of completing all the Quests',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-07T12:51:23.086Z',
        updatedAt: '2022-06-07T12:51:23.086Z',
        reward: [
          {
            type: 'xp',
            value: '100',
          },
          {
            type: 'role',
            value: '983738523469348884',
          },
        ],
        position: 2,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'image',
        condition: {
          type: 'quest',
          value: 'b7ae51a1-ee0b-4471-84a8-1274ca3dd96b',
          operator: 'isCompleted',
        },
        validationData: {},
        autoValidate: false,
        id: '32be5739-3c12-412d-8b08-299a615731bf',
        categoryId: '1c4f1e4b-b099-4b70-a2ae-aeefc1949ea5',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Mr.KnowAll of Twitter 😎',
        description: 'Turn on notifications for twitter account of Brine',
        content: [
          {
            id: '1',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '2QB5g8ycFr',
            data: {
              text: 'Turn on notifications for twitter account of Brine : Link Below 👇🏼',
            },
            type: 'paragraph',
          },
          {
            id: '2',
            data: {
              text: 'https://twitter.com/BrineFinance',
            },
            type: 'paragraph',
          },
          {
            id: 'QVQF2wtlwE',
            data: {
              text: '(Click on the Bell Icon)',
            },
            type: 'paragraph',
          },
          {
            id: '5',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '6',
            data: {
              text: 'Submit a screenshot after turning on notifications 🚀',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-07T19:31:18.083Z',
        updatedAt: '2022-06-07T19:31:18.083Z',
        reward: [
          {
            type: 'xp',
            value: '110',
          },
        ],
        position: 3,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'image',
        condition: {
          type: 'quest',
          value: 'b7ae51a1-ee0b-4471-84a8-1274ca3dd96b',
          operator: 'isCompleted',
        },
        validationData: {},
        autoValidate: false,
        id: '27c7a49d-210e-4807-afd5-590eb0d15941',
        categoryId: '1c4f1e4b-b099-4b70-a2ae-aeefc1949ea5',
        status: 'open',
        inReview: false,
        locked: false,
      },
      {
        name: 'Defi Warrior 👑',
        description:
          'Submit a link to any transaction that you did on Ethereum, BSC or Polygon Mainnet',
        content: [
          {
            id: '1',
            data: {
              text: 'MISSION 🎯',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '2',
            data: {
              text: 'Submit a link to any transaction that you did on Ethereum, BSC or Polygon Mainnet.',
            },
            type: 'paragraph',
          },
          {
            id: '3',
            data: {
              text: 'IMPORTANT 🛑',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '4',
            data: {
              text: '<b>Address of the transaction should be the same as the address linked on Crew3.</b>',
            },
            type: 'paragraph',
          },
          {
            id: '5',
            data: {
              text: 'SUBMISSION 📝',
              level: 3,
            },
            type: 'header',
          },
          {
            id: '6',
            data: {
              text: 'Submit URL to the transaction',
            },
            type: 'paragraph',
          },
        ],
        recurrence: 'once',
        deleted: false,
        createdAt: '2022-06-09T08:45:43.626Z',
        updatedAt: '2022-06-09T08:45:43.626Z',
        reward: [
          {
            type: 'xp',
            value: '120',
          },
          {
            type: 'role',
            value: '984377566385287208',
          },
        ],
        position: 4,
        published: true,
        communityId: 'b518a43d-247d-429c-bb26-04970ab31f50',
        submissionType: 'url',
        condition: {},
        validationData: {},
        autoValidate: false,
        id: 'c9580c6a-c69b-4797-81d5-7d5f10b208b8',
        categoryId: '1c4f1e4b-b099-4b70-a2ae-aeefc1949ea5',
        status: 'open',
        inReview: false,
        locked: false,
      },
    ],
  },
];
