import React from 'react';
import Marquee from 'react-fast-marquee';

function MarqueeContainer({ children, direction = 'right', ...otherProps }) {
  return (
    <Marquee
      gradientWidth={200}
      gradientColor={[10, 10, 10]}
      pauseOnHover={true}
      direction={direction}
      gradient={true}
      {...otherProps}
    >
      {children}
    </Marquee>
  );
}

export default MarqueeContainer;
