import React from 'react';

function Button({ variant = 'primary', children, ...props }) {
  let color = {
    primary: 'btn btn-primary',
    secondary: 'btn btn-secondary',
    linear: 'btn btn-linear',
    dark: 'btn btn-dark',
  };
  return (
    <button {...props} className={color[variant]}>
      {children}
    </button>
  );
}

export default Button;
