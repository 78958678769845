import React, { useState, useEffect } from 'react';
import HandPNG from '../../assets/hand.png';
import ETHCoin from '../../assets/eth-coin.png';
import BgRing from '../../assets/bg-ring.png';
import Avatar1 from '../../assets/avator-1.png';
import Avatar2 from '../../assets/avator-2.png';

function Banner({ offsetY }) {
  return (
    <div className="banner" style={{ position: 'relative' }}>
      <div className="banner-text">
        <h2>
          Extract Your Salt <span className="color-purple">&</span> Claim
          Exclusive Rewards<span className="color-purple">.</span>
        </h2>
        <p>
          Earn SALT points for all your engagements in the Ethereum blockchain &
          Brine.
        </p>
      </div>
      <AddressCard
        className={'nft_claimed'}
        title="NFT claimed"
        subtitle="Salt Points Redeemed ✅"
        address="0x03455....345555"
        transform={{
          transform: `translateY(${offsetY * 0.1}px)`,
        }}
        img={Avatar1}
      />
      <AddressCard
        className={'eth_deposit glass'}
        title="+1.002 ETH 🎉"
        subtitle="Salt Points Redeemed ✅"
        address="0x03455....345555"
        transform={{
          transform: `translateY(${offsetY * 0.1}px)`,
        }}
        img={Avatar2}
      />
      <div className="hand_img_container">
        <img src={HandPNG} alt="" />
      </div>
    </div>
  );
}

export default Banner;

function AddressCard({ title, address, subtitle, className, transform, img }) {
  return (
    <div className={className}>
      <div className="address_card" style={transform}>
        <div
          style={{
            display: 'flex',
          }}
        >
          <img src={img} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h6
              style={{
                marginLeft: 5,
              }}
            >
              {address}
            </h6>
            <p>{subtitle}</p>
          </div>
        </div>
      </div>
      {className == 'eth_deposit glass' && (
        <>
          <div
            style={{
              padding: '0px 20px',
              marginTop: 9,
            }}
          >
            <img
              src={ETHCoin}
              alt=""
              style={{
                height: 150,
              }}
            />
          </div>
          <div className="circle-4">
            <img src={BgRing} alt="" />
          </div>
        </>
      )}
      <button>{title}</button>
    </div>
  );
}
