import React, { useContext, useEffect, useState } from 'react';
import Stepper from './Stepper';
import Button from './Button';
import Lottie from 'react-lottie';
import { WalletContext } from '../../context/WalletContext';
import CasinoAnimationData from '../../assets/score.json';
import LoadingAnimationData from '../../assets/loading.json';
import { getScore, getTransaction } from '../../actions/saltAction';
import { toast, ToastContainer } from 'react-toastify';
import {
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import useViewPort from '../hooks/useViewPort';

function Salt() {
  const {
    connectWallet,
    account,
    signMessage,
    disconnect,
    signature,
    getBalance,
    token_contract,
  } = useContext(WalletContext);
  const [balances, setBalance] = useState({});
  const [score, setScore] = useState(null);
  const [msgHash, setMsgHash] = useState(null);
  const [loading, setLoading] = useState(false);
  const { width } = useViewPort();

  const CasinoAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: CasinoAnimationData,
  };

  const LoadingAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimationData,
  };

  const getSignAndBalance = async () => {
    // const balances = {};
    // let sign;
    // try {
    //   sign = await signMessage();
    //   if (sign) {
    //     setMsgHash(sign);
    //   }
    //   setLoading(true);
    // } catch (error) {
    //   if (error == 'chain error') return;
    //   toast.error(error);
    // }
    // for (const key in token_contract) {
    //   const balance = await getBalance(key);
    //   balances[key] = balance?.toString();
    // }
    // setBalance(balances);
    // handleGetTransaction(sign, account, balances);
    setLoading(true);
    try {
      const data = await getScore(account);
      setScore(data);
      toast.success(data.message);
      setLoading(false);
    } catch (error) {
      toast.error(
        error == 'Request failed with status code 429'
          ? 'Too many requests please try again later'
          : error
      );
      setLoading(false);
    }
  };

  const handleGetTransaction = async (sign, account, bal) => {
    try {
      const { data } = await getTransaction(account);
      let reqData = {
        balances: bal,
        transactions: data,
        signature: sign,
      };
      console.log({ reqData });
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (account) => {
        setMsgHash(null);
      });
    }
  }, []);

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }} id='salt'>
      <div className='salt_container_text'>
        <h3>
          Calculate your Salt Score
          <span className='color-purple'>.</span>
        </h3>
        <p>
          Calculate your SALT score effortlessly by connecting your wallet, SALT
          score gets updated every week
        </p>
      </div>
      <div className='salt-container' style={{ position: 'relative' }}>
        <div className='salt-circle1'>{/* <img src={BgImage} alt="" /> */}</div>
        <div className='salt-circle2'>{/* <img src={BgImage} alt="" /> */}</div>
        <div className='salt-innner-container glass'>
          <div style={{ position: 'absolute' }}>
            {/* <div className="salt-line-bg"></div>
            <div className="salt-circle-2">
              <img src={BgCircle2} alt="" />
            </div>
            <div className="salt-circle-3">
              <img src={BgCircle3} alt="" />
            </div> */}
          </div>
          {loading ? (
            <div className='absolute_center'>
              <div className='loading_animation'>
                <Lottie
                  options={LoadingAnimationOptions}
                  height={'100%'}
                  width={'100%'}
                />
                <h2 style={{ maxWidth: 200 }}>Compounding your salt...</h2>
              </div>
            </div>
          ) : (
            <>
              <div className='salt-calculate-container'>
                <div className='salt-button-container'>
                  <Stepper stage={!msgHash ? 'stage-0' : 'stage-1'} />
                  {score && score.payload !== '' ? (
                    <>
                      <Button onClick={() => disconnect()} variant='dark'>
                        <i
                          style={{ marginRight: 5 }}
                          className='fas fa-unlink'
                        ></i>
                        Disconnect
                      </Button>
                      {/* <Button variant="secondary" style={{ marginTop: 20 }}>
                        Connect Twitter
                      </Button> */}
                      <Button style={{ marginTop: 20 }}>Connect Discord</Button>
                      <h4>
                        Connect your social handles for increasing your SALT
                        score
                      </h4>
                      <a href='#'>Learn more</a>
                    </>
                  ) : (
                    <>
                      {' '}
                      {account?.length == 0 || !account ? (
                        <Button onClick={connectWallet}>
                          <i className='fas fa-link'></i> Connect Wallet
                        </Button>
                      ) : (
                        <>
                          <Button
                            style={{ marginBottom: 20 }}
                            onClick={getSignAndBalance}
                          >
                            <i className='fas fa-star'></i> Get Score
                          </Button>
                          <Button variant='dark' onClick={() => disconnect()}>
                            <i
                              style={{ marginRight: 5 }}
                              className='fas fa-unlink'
                            ></i>
                            Disconnect
                          </Button>
                        </>
                      )}
                      <h4>Connect your wallet to extract your SALT score</h4>
                      <a href='#'>Learn more</a>
                    </>
                  )}
                </div>
                <div
                  className='salt-result-container'
                  style={{ position: 'relative' }}
                >
                  {score && score.payload !== '' ? (
                    <div className='result_container absolute_center'>
                      <span className='result-number'>
                        {score.payload.salt_score}
                      </span>
                      <h3 className='color-purple'>SALT SCORE</h3>
                      {/* <Button
                        variant="linear"
                        style={{ marginTop: 20, pointerEvents: 'none' }}
                      >
                        RANK #1223
                      </Button> */}
                      <br />
                      <br />
                      <h4>Share it with your friends</h4>
                      <div className='share__social_icon'>
                        {/* <LinkedinShareButton
                          title={'title'}
                          summary={'summary'}
                          source="brine"
                          url="brine.finance"
                        >
                          <LinkedinIcon size={35} round />
                        </LinkedinShareButton> */}
                        <TwitterShareButton
                          url={'url'}
                          title={'title'}
                          via='Brine'
                          style={{ marginLeft: 10 }}
                        >
                          <TwitterIcon size={35} round />
                        </TwitterShareButton>
                      </div>
                      <a href='#earn_xp_section'>
                        How to Increase your SALT score ? 🚀
                      </a>
                    </div>
                  ) : (
                    <div className='absolute_center'>
                      <div>
                        <Lottie
                          options={CasinoAnimationOptions}
                          height={width > 1700 ? 500 : 350}
                          width={width > 1700 ? 500 : 350}
                        />
                      </div>
                      {/* <Button
                        variant="linear"
                        style={{ marginTop: 5, pointerEvents: 'none' }}
                      >
                        SALT SCORE
                      </Button> */}
                      <br />
                      <br />
                      <a href='#earn_xp_section'>
                        How to Increase your SALT score ? 🚀
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Salt;
