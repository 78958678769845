import axios from 'axios';
import { baseUrl } from '../utils/constants';

export const getTransaction = async (address) => {
  let params = {
    module: 'account',
    action: 'txlist',
    address: address,
    startblock: 0,
    endblock: 99999999,
    page: 1,
    offset: 10000,
    sort: 'asc',
  };
  try {
    const res = await axios.get(`https://api.etherscan.io/api`, {
      params: params,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getLeaderboard = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/fetch/leaderboard/`);
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const getScore = async (wallet_address) => {
  try {
    const config = {};
    const { data } = await axios.post(
      `${baseUrl}/salt_score/`,
      { wallet_address: wallet_address },
      config
    );
    return data;
  } catch (error) {
    throw error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};
