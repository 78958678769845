import React, { createContext, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import Web3Modal from 'web3modal';
import { providerOptions } from '../provider/index';
import erc_20_abi from '../components/contracts/erc20_abi.json';

export const WalletContext = createContext();

// let infura_key =
//   'https://mainnet.infura.io/v3/ee8a7567372541eca3bfdc7f4e8b0ea1';

const web3Modal = new Web3Modal({
  network: 'mainnet',
  cacheProvider: false, // optional
  providerOptions, // required
  theme: 'dark',
  supportedChainIds: [5],
});

export const WalletProvider = ({ children }) => {
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [signature, setSignature] = useState('');
  const [error, setError] = useState('');
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [message, setMessage] = useState('');
  const [signedMessage, setSignedMessage] = useState('');
  const [verified, setVerified] = useState();

  let signer;
  let providerStatic;

  if (library) {
    providerStatic = new ethers.providers.Web3Provider(library?.provider);
    signer = library.getSigner();
  }

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      console.log(network.chainId, provider, library);
      setChainId(network.chainId);
      if (network.chainId != 1) {
        return toast.loading(<ChainError />, {
          toastId: 'Chain_Id_Error',
        });
      }
    } catch (error) {
      setError(error);
    }
  };

  const switchNetwork = async (wallet) => {
    console.log(wallet);
    try {
      await wallet.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await wallet.request({
            method: 'wallet_addEthereumChain',
            params: [{ chainId: '0x1' }],
          });
        } catch (error) {
          setError(error);
        }
      }
    }
  };

  const signMessage = async () => {
    if (chainId != 1) {
      toast.loading(
        <ChainError />,
        {
          toastId: 'Chain_Id_Error',
        },
        {
          closeOnClick: false,
        }
      );
      throw 'chain error';
    }
    if (!library) return;
    try {
      const signature = await library.provider.request({
        method: 'personal_sign',
        params: [
          'Find my Brine SALT Score - Please make sure the host is www.brine.finance',
          account,
        ],
      });
      setSignedMessage(message);
      setSignature(signature);
      return signature;
    } catch (error) {
      setError(error);
      throw error;
    }
  };

  console.log({ library });

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork('');
    setMessage('');
    setSignature('');
    setVerified(undefined);
  };

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    window.location.reload();
    refreshState();
  };

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log('accountsChanged', accounts);
        if (accounts) setAccount(accounts[0]);
      };

      const handleChainChanged = (_hexChainId) => {
        window.location.reload();
      };

      const handleDisconnect = () => {
        console.log('disconnect', error);
        disconnect();
      };

      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);
      provider.on('disconnect', handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('chainChanged', handleChainChanged);
          provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }
  }, [provider]);

  const token_contract = {
    usdt: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    btc: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    usdc: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    eth: '',
  };

  const getBalance = async (symbol) => {
    if (!library) return;
    if (symbol == 'eth') {
      const ethBalance = await library.getBalance(account);
      return ethers.utils.formatEther(ethBalance);
    } else {
      const tokenContractAddress = token_contract[symbol];
      if (tokenContractAddress) {
        const contract = new ethers.Contract(
          tokenContractAddress,
          erc_20_abi,
          providerStatic
        );
        const address = await signer.getAddress();
        const balance = (await contract.balanceOf(address)).toString();
        return balance;
      }
    }
  };

  useEffect(() => {
    if (error) {
      if (error == 'Modal closed by user') return;
      toast.error(error);
    }
  }, [error]);

  const ChainError = ({ closeToast, toastProps }) => {
    return (
      <div style={{ textAlign: 'left', fontSize: 14 }}>
        <p className="font-bold">Oops, You seem to be on the wrong lane.</p>
        <p>Kindly change your network to Mainnet</p>
      </div>
    );
  };

  return (
    <WalletContext.Provider
      value={{
        provider,
        setProvider,
        library,
        setLibrary,
        account,
        setAccount,
        signature,
        setSignature,
        error,
        setError,
        chainId,
        setChainId,
        network,
        setNetwork,
        message,
        setMessage,
        signedMessage,
        setSignedMessage,
        verified,
        setVerified,
        signMessage,
        switchNetwork,
        connectWallet,
        disconnect,
        getBalance,
        token_contract,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
