import React from 'react';
import TitleImg1 from '../../assets/instruction-title-1.png';
import InstructionImg1 from '../../assets/instruction-img-1.png';
import TitleImg2 from '../../assets/instruction-title-2.png';
import InstructionImg2 from '../../assets/instruction-img-2.png';
import TitleImg3 from '../../assets/instruction-title-3.png';
import InstructionImg3 from '../../assets/instruction-img-3.png';

function Instruction() {
  return (
    <div className='instruction_wrapper'>
      <div className='instruction container'>
        <h3>
          Get rewarded for being a part of the community
          <span className='color-purple'>.</span>
        </h3>
        <p className='instruction_subtitle'>
          Every time you trade, swap, lend, borrow or vote in Ethereum Mainnet,
          you get SALT points which increase your score.
        </p>
        <div className='instruction__container'>
          <div className='instruction_item'>
            <div className='instruction_title'>
              <img src={TitleImg1} alt='' />
              <h2>1. Interact with the Blockchain</h2>
            </div>
            <p>
              SALT points are earned every time you interact with the
              blockchain, which might include swapping. trading, staking,
              lending, and anything else that involves spending gas.
            </p>
          </div>
          <div className='instruction_item instruction_img'>
            <img src={InstructionImg1} alt='' />
          </div>
        </div>
        <div className='instruction__container'>
          <div className='instruction_item instruction_img start'>
            <img src={InstructionImg2} alt='' />
          </div>
          <div className='instruction_item'>
            <div className='instruction_title'>
              <img src={TitleImg2} alt='' />
              <h2>2. Connect your social handles</h2>
            </div>
            <p>
              Increase your SALT score by engaging with crypto or Brine related
              topics on Twitter or{' '}
              <a
                href='https://discord.com/invite/wuPHBr9e9y'
                target='_blank'
                rel='noopener noreferrer'
              >
                Brine Discord Channel
              </a>
              . Do keep in mind that spamming will not provide points.
            </p>
          </div>
        </div>
        <div className='instruction__container'>
          <div className='instruction_item'>
            <div className='instruction_title'>
              <img src={TitleImg3} alt='' />
              <h2>3. Claim your Rewards</h2>
            </div>
            <p>
              Your SALT score allows you to redeem multiple rewards, which might
              include Exclusive NFTs, Fee rebates, Cryptos upto 100k$, access to
              mainnet, merchandise & many more.
            </p>
          </div>
          <div className='instruction_item instruction_img'>
            <img src={InstructionImg3} alt='' />
          </div>
        </div>
      </div>{' '}
    </div>
  );
}

export default Instruction;
