import React from 'react';
import { crew3XpData } from '../../utils/crew3xpData';

function EarnXp() {
  return (
    <div className='earn_xp_section' id='earn_xp_section'>
      <div className='instruction container'>
        <h3>
          Earn More SALT
          <span className='color-purple'>.</span>
        </h3>
        <p className='instruction_subtitle' style={{ marginBottom: 30 }}>
          Complete Quests on Crew3 to earn more SALT score, Letz Goo 🚀 (SALT
          Score Updated every Sunday)
        </p>
      </div>
      <div
        className='crew-3xp-container container'
        style={{ maxWidth: '1216px' }}
      >
        {/* <div className="crew-3xp">
          <h6>
            CREW <sup>3</sup> XP
          </h6>
          <h3>2636</h3>
        </div> */}
      </div>
      <div
        className='grid_earn_container container'
        style={{ maxWidth: '1216px', overflow: 'hidden' }}
      >
        {crew3XpData.map((item) => {
          return item.quests.map((quest) => (
            <div
              className='earn_item'
              onClick={() =>
                window.open(
                  `https://brine.crew3.xyz/questboard?q=${quest.id}`,
                  '_blank'
                )
              }
            >
              <h1>{quest.name}</h1>
              <p>{quest.description}</p>
              <h6>{quest.reward[0].value} xp</h6>
            </div>
          ));
        })}
      </div>
    </div>
  );
}

export default EarnXp;
