import WalletConnect from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

export const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: 'Brine salt', // Required
      infuraId: 'ee8a7567372541eca3bfdc7f4e8b0ea1', // Required unless you provide a JSON RPC url; see `rpc` below
    },
  },
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: 'ee8a7567372541eca3bfdc7f4e8b0ea1', // required
    },
  },
};
