import React, { useEffect, useState } from 'react';
import MarqueeContainer from './MarqueeContainer';
import TrophyImg from '../../assets/trophy-dynamic-gradient.png';
import truncateEthAddress from '../../utils/truncateAddress';
import { getLeaderboard } from '../../actions/saltAction';

const MarqueeItem = ({ item }) => {
  return (
    <div className="marquee-item">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <h3>{truncateEthAddress(item.address)}</h3>
        <h6 style={{ marginLeft: 50, fontSize: 35, color: '#11A5C6' }}>
          #{item.rank}
        </h6>
      </div>
      <p className="color-purple">Salt Score:{item?.score}</p>
    </div>
  );
};

// const dummy = {
//   status: 'success',
//   message: 'Leaderboard details fetched successfully',
//   payload: {
//     1: ['0x60562cb486215BD9eA778d882b1C46D9e21B8efA', 3260],
//   },
// };

function LeaderBoard() {
  const [row1, setRow1] = useState([]);
  const [row2, setRow2] = useState([]);
  const [row3, setRow3] = useState([]);
  const [leaderBoardData, setLeaderBoardData] = useState(null);

  useEffect(() => {
    if (leaderBoardData) {
      let covertedArr = Object.keys(leaderBoardData?.payload).map((e) => ({
        address: leaderBoardData?.payload[e][0],
        score: leaderBoardData?.payload[e][1],
        rank: e,
      }));
      const n = Math.round(Object.keys(leaderBoardData.payload).length / 3);
      if (Object.keys(leaderBoardData?.payload).length > 20) {
        const result = new Array(Math.ceil(covertedArr.length / n))
          .fill()
          .map((_) => covertedArr.splice(0, n));
        setRow1(result[0]);
        setRow2(result[1]);
        setRow3(result[2]);
      } else {
        const result = new Array(Math.ceil(covertedArr.length / n))
          .fill()
          .map((_) => covertedArr.splice(0, n));
        setRow1(result[0]);
        setRow2(result[1]);
      }
    }
  }, [leaderBoardData]);

  const getLeaderboardList = async () => {
    try {
      const data = await getLeaderboard();
      setLeaderBoardData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeaderboardList();
  }, []);

  return (
    <>
      <div
        style={{ background: '#0f0f0f', position: 'relative' }}
        className="leader_board"
      >
        <div className="instruction" style={{ marginTop: 20 }}>
          <h3>
            The Leaderboard
            <span className="color-purple">.</span>
          </h3>
          <p className="instruction_subtitle">
            The Top 50 leaders in the leaderboard will be awarded exclusive
            rewards and the rest will always be cherished in our heart.
          </p>
        </div>
        <div style={{ position: 'relative' }}>
          <div className="trophy_image">
            <img src={TrophyImg} alt="" />
          </div>
          <div style={{ marginTop: '20px' }}>
            <MarqueeContainer gradientWidth={20}>
              {row1.map((item, idx) => (
                <MarqueeItem key={idx} item={item} />
              ))}
            </MarqueeContainer>
          </div>
          <div style={{ marginTop: '20px' }}>
            <MarqueeContainer gradientWidth={20} direction="left">
              {row2.map((item, idx) => (
                <MarqueeItem key={idx} item={item} />
              ))}
            </MarqueeContainer>
          </div>
          <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <MarqueeContainer gradientWidth={20}>
              {row3.map((item, idx) => (
                <MarqueeItem key={idx} item={item} />
              ))}
            </MarqueeContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaderBoard;
